/*placed in html head*/

html {
  scroll-behavior: smooth;
}

body {
  /*background: rgb(230, 230, 230);*/
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.ProseMirror > ul > li {
  list-style-type: circle;
}

div.ProseMirror > ol > li {
  list-style-type: decimal;
}

/*custom scrollbar*/

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
